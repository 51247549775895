import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import { FaArrowLeft } from "react-icons/fa";
import { SEO, Layout } from 'components';
import config from '../../config/website';

import './project.scss';

const Backbutton = styled.button`
  color: grey;
  padding-top: 1em;
  background-color: transparent;
  border: none;
  &:hover {
    opacity: 0.5;
  }
  margin-bottom: 2em;
`;

const Project = ({ pageContext: { slug }, data: { markdownRemark: postNode } }) => {
  const project = postNode.frontmatter;
  return (
    <Layout>
      <Helmet title={`${project.title} | ${config.siteTitle}`} />
      <SEO postPath={slug} postNode={postNode} postSEO />
      <div className="project-page">
        <div className="container">
          <div className="row">
            <div className="col-sm">
              <Backbutton onClick={() => window.history.back()}> <FaArrowLeft /> back</Backbutton>
              <h2 className="heading">{project.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: postNode.html }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Project;

Project.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    markdownRemark: PropTypes.object.isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query ProjectPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        # date(formatString: "DD.MM.YYYY")
        # client
        # service
        cover {
          childImageSharp {
            resize(width: 992) {
              src
            }
          }
        }
      }
      fields {
        slug
      }
    }
  }
`;
